"use client";

import { allQueryKeys } from "#app/_api/allQueryKeys";
import config from "#app/env";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { bindIcFunctions } from "./api";

type IcIframeWithResizer = HTMLIFrameElement & { iFrameResizer: { sendMessage: (obj: any) => void } };

export type IcIframeSessionManager = {
    signOut: () => void
}

export function IcSessionIframe() {
    const { data } = useQuery(allQueryKeys.bolAuthenticationService.saml);

    useEffect(() => {
        if (data) {
            const icSessionManager = `<form id="icsessionmanagerform" class="hidden" action=${config!.icIframeUrl!} method="POST" target="icsessionmanager">
                    <input type="hidden" name="RelayState" value=${config!.icUrl!} />
                    <input type="hidden" name="SAMLResponse" value=${data} />
                </form>
                <iframe id="icsessionmanager" width="0" height="0" class="hidden" name="icsessionmanager" />`;
            const icSessionManagerDiv = document.createElement("div");
            icSessionManagerDiv.id = "icsessionmanagercontainer";
            icSessionManagerDiv.innerHTML = icSessionManager;
            icSessionManagerDiv.querySelector("#icsessionmanager")?.addEventListener("load", () => bindIcFunctions(config!.icUrl!));
            document.body.appendChild(icSessionManagerDiv);

            const form = document.getElementById("icsessionmanagerform") as HTMLFormElement;

            form.submit();
        }
        return () => {
            const container = document.getElementById("icsessionmanagercontainer") as HTMLDivElement;
            container?.remove();
        }
    }, [data]);

    return null;
}

export function signOut() {
    const iframe = document.getElementById("icsessionmanager") as IcIframeWithResizer;
    iframe?.iFrameResizer?.sendMessage({ eventType: "LOGOUT" });
    setTimeout(() => {
        const container = document.getElementById("icsessionmanagercontainer");
        container?.remove();
    }, 1000)
}